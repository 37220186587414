/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-22 16:03:10
 * @FilePath: \mid-autumn\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home/first',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
    children: [
      {
        path: '/home/first',
        name: 'first',
        component: () => import(/* webpackChunkName: "index" */ '@/views/home/first/index.vue'),
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "login" */ '@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
